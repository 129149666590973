<template>
  <v-container>
    <v-row>
      <v-col>
        <v-treeview
          dense
          :items="items"
          :active="activeItems"
          :open="openedItems"
          @update:active="onActive"
          @update:open="onOpen"
        >
          <template v-slot:label="{ item }">
            <div v-if="item.type == 'group'">{{ item.data.groupOp }}</div>
            <div v-if="item.type == 'rules'">Rules</div>
            <div v-if="item.type == 'rule'">
              <v-row align="center">
                <v-col cols="3">{{ item.data.field }}</v-col>
                <v-col cols="3">{{ item.data.operator }}</v-col>
                <v-col cols="3">{{ item.data.dataset }}</v-col>
              </v-row>
            </div>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toTreeData } from "@/managers/rules";

export default {
  name: "RulesetCard",
  components: {},
  props: {
    rule: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      items: [],
      openedItems: [],
      activeItems: []
    };
  },
  created: async function() {
    this.init();
  },
  watch: {
    rule() {
      this.init();
    }
  },
  methods: {
    init: async function() {
      await this.updateItems();
      this.openFirstLevel();
    },
    updateItems: async function() {
      // console.log('updateItems', this.rule)

      if (!this.rule || !this.rule.ruleset) {
        this.items = [];
        return;
      }
      this.items = toTreeData(JSON.parse(this.rule.ruleset));
    },
    openFirstLevel() {
      if (this.items == null || !Array.isArray(this.items)) return;
      if (this.items.length != 1) return;
      const rootItem = this.items[0];
      const firstLevelIds = rootItem.children.map(p => p.id);
      this.openedItems = [];
      this.openedItems.push(rootItem.id);
      this.openedItems.push(...firstLevelIds);
    },
    onOpen: function(event) {
      this.openedItems = event;
    },
    onActive: function(event) {
      this.activeItems = event;
      if (this.activeItems.length != 1) return;
      this.onClick(this.activeItems[0]);
    },
    onClick(nodeId) {
      if (this.isNodeLeaf(nodeId)) {
        this.$emit("input", nodeId);
      }
    },
    findNodeById(nodeId) {
      if (this.items.length != 1) return null;
      return this.findNodeByIdReq(nodeId, this.items[0]);
    },
    findNodeByIdReq(nodeId, parentNode) {
      if (parentNode.id == nodeId) return parentNode;
      for (const childNode of parentNode.children) {
        const node = this.findNodeByIdReq(nodeId, childNode);
        if (node != null) return node;
      }
    },
    isNodeLeaf(node) {
      if (!node.children) return true;
      if (node.children.length == 0) return true;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
