export const toTreeData = resource => {
  return resource.groups
    .filter(p => p.id.split(".").length == 1 && groupBlackList(p))
    .map(group => createGroupNode(group, resource));
};

function groupBlackList(group) {
  return group.id !== "_abac";
}

const findChildGroups = (groupId, resource) => {
  return resource.groups.filter(p => p.id.startsWith(groupId + "."));
};

const findChildEndpoints = (groupId, resource) => {
  return resource.endpoints.filter(p => p.groupId === groupId);
};

export const createGroupNode = (group, resource) => {
  let groupNode = {
    id: group.id,
    name: group.description,
    type: "group",
    data: {},
    children: []
  };

  const childGroups = findChildGroups(groupNode.id, resource);
  const childEndPoints = findChildEndpoints(groupNode.id, resource);

  const childGroupNodes = childGroups.map(childGroup =>
    createGroupNode(childGroup, resource)
  );
  const childEndPointNodes = childEndPoints.map(childEndPoint =>
    createEndPointNode(childEndPoint, resource)
  );

  groupNode.children = childEndPointNodes.concat(childGroupNodes);

  return groupNode;
};

export const createEndPointNode = endpoint => {
  let endpointNode = {
    id: endpoint.id,
    name: endpoint.description,
    method: endpoint.method,
    type: "endpoint",
    data: {
      id: endpoint.id,
      endpoint: endpoint.endpoint
    },
    children: []
  };

  // if (endpoint.rulesets) {
  // 	for (let ruleset of endpoint.rulesets) {
  // 		let rulesetNode = createRuleSetNode(ruleset)
  // 		endpointNode.children.push(rulesetNode)
  // 		rulesetNode.parent = endpointNode
  // 	}
  // }

  // if (endpoint.endpoints) {
  // 	for (let subEndpoint of endpoint.endpoints) {
  // 		let subEndpointNode = createEndPointNode(subEndpoint)
  // 		endpointNode.children.push(subEndpointNode)
  // 		subEndpointNode.parent = endpointNode
  // 	}
  // }

  return endpointNode;
};

export const createRuleSetNode = ruleset => {
  let rulesetNode = {
    id: ruleset.name, //id => name
    name: ruleset.name,
    type: "ruleset",
    data: {
      id: ruleset.id,
      name: ruleset.name
    }
  };
  return rulesetNode;
};
