<template>
  <v-container fluid>
    <v-row align="start">
      <v-col cols="6">
        <h1 class="mb-6">
          <strong>{{ $t("resource") }}:</strong> {{ resourceId }}
        </h1>
        <GsEndPoints v-model="endpoint" :resourceId="resourceId" />
      </v-col>
      <v-col cols="6">
        <v-card class="elevation-4 pa-6" v-if="endpoint && endpoint.id">
          <v-card-title class="ma-0">
            <v-row>
              <v-col cols="8">
                <p>
                  <strong>{{ endpoint.id }}</strong>
                </p>
                <p class="body-2">{{ endpoint.name }}</p>
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="error"
                  v-on:click="deleteClick()"
                  v-if="mode == modes.MODE_BROWSE"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" align="right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="primary"
                  v-on:click="editClick()"
                  v-if="mode == modes.MODE_BROWSE"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex flex-row-reverse">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="accent"
                    v-on:click="saveRule()"
                    v-if="mode != modes.MODE_BROWSE"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    color="accent"
                    v-on:click="cancel()"
                    v-if="mode != modes.MODE_BROWSE"
                  >
                    <v-icon>mdi-undo</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="ma-0">
            <GsRulesetCard :rule="rule" v-if="mode == modes.MODE_BROWSE" />
            <GsRulesetCardEditor
              :rule="rule"
              ref="editor"
              v-if="mode != modes.MODE_BROWSE"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsEndPoints from "@/components/GsEndPoints";
import GsRulesetCard from "@/components/GsRulesetCard";
import GsRulesetCardEditor from "@/components/GsRulesetCardEditor";

export default {
  name: "Home",
  components: {
    GsEndPoints,
    GsRulesetCard,
    GsRulesetCardEditor
  },
  data() {
    return {
      endpoint: { id: null, name: null },
      rule: null,
      mode: null,
      modes: { MODE_BROWSE: "browse", MODE_ADD: "add", MODE_EDIT: "edit" }
    };
  },
  watch: {
    endpoint: function() {
      this.loadRule();
    }
  },
  computed: {
    resourceId: function() {
      return this.$store.state.selected_resource;
    }
  },
  created: function() {
    this.mode = this.modes.MODE_BROWSE;
  },
  methods: {
    editClick: function() {
      if (this.rule == null) {
        let data = this.endpoint.id.split(":");
        this.rule = {
          resource: data[0],
          method: data[1],
          ruleset: JSON.stringify({ groupOp: "AND", rules: [], groups: {} })
        };
        this.mode = this.modes.MODE_ADD;
      } else {
        this.mode = this.modes.MODE_EDIT;
      }
    },
    deleteClick() {
      const result = confirm(this.$t("delete_modal"));
      if (!result) {
        return;
      }
      callAPI({
        url: `${this.resourceId}/_abac/policies/${encodeURIComponent(
          this.endpoint.id
        )}`,
        method: "DELETE"
      })
        .then(this.loadRule())
        .catch(error => {
          console.log(error);
        });
    },
    async loadRule() {
      if (this.endpoint.id == null || this.resourceId == null) {
        this.rule = null;
        return;
      }
      try {
        const result = await callAPI({
          url:
            this.resourceId +
            "/_abac/policies/" +
            encodeURIComponent(this.endpoint.id),
          method: "GET"
        });
        this.rule = result.data;
      } catch (err) {
        this.rule = null;
        return;
      }
    },
    async saveRule() {
      this.rule = this.$refs.editor.getRule();
      try {
        if (this.mode == this.modes.MODE_ADD) {
          await callAPI({
            url: this.resourceId + "/_abac/policies",
            method: "POST",
            data: this.rule
          });
        }
        if (this.mode == this.modes.MODE_EDIT) {
          await callAPI({
            url:
              this.resourceId +
              "/_abac/policies/" +
              encodeURIComponent(this.endpoint.id),
            method: "PATCH",
            data: {
              ruleset: this.rule.ruleset
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.rule = null;
        this.items = [];
        return;
      }
      this.mode = this.modes.MODE_BROWSE;
    },
    cancel() {
      this.mode = this.modes.MODE_BROWSE;
    }
  }
};
</script>

<style lang="scss">
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

/* redefine base styles */
body {
  background-color: $gray3;
}
.v-application {
  // .pa-0 {
  //   padding: 20px 26px !important;
  // }
  .primary--text {
    color: $darkgreen !important;
    caret-color: $darkgreen !important;
  }
}
::selection {
  background-color: $lightgreen !important;
  color: $black;
}
h1 {
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 0.15px;
  font-size: 26px;
}
.v-data-table {
  th {
    font-size: 100%;
    font-weight: 700;
  }
}
.default-button {
  background-color: $lightgreen !important;
}
</style>
