<template>
  <v-container
    class="GsRuleEditor"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-row align="center">
      <v-col cols="3">
        <GsAutoComplete
          label="field"
          v-model="value.data.field"
          :hover="hover"
        />
      </v-col>
      <v-col cols="3">
        <GsAutoComplete
          label="operator"
          v-model="value.data.operator"
          :hover="hover"
        />
      </v-col>
      <v-col cols="3">
        <GsAutoComplete
          label="dataset"
          v-model="value.data.dataset"
          :hover="hover"
        />
      </v-col>
      <v-col cols="3">
        <v-btn
          class="mx-2 remove-button elevation-0"
          fab
          dark
          x-small
          color="error"
          v-on:click="removeClick(value)"
          v-if="!value.isRoot"
        >
          <v-icon dense x-small right style="margin: 0px; padding: 0px;"
            >mdi-minus</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GsAutoComplete from "./GsAutoComplete";

export default {
  name: "GsRuleEditor",
  components: {
    GsAutoComplete
  },
  props: ["value"],
  data() {
    return {
      hover: false
    };
  },
  methods: {
    removeClick: function(item) {
      let index = item.parent.children.indexOf(item);
      item.parent.children.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

.container {
  padding: 0;
}
.remove-button {
  background-color: $red !important;
}
</style>
