<template>
  <div>
    <v-text-field
      :placeholder="label"
      :value="value"
      @input="updateValue"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "GsAutoComplete",
  props: {
    label: {
      type: String,
      default: "",
      validator() {
        return true;
      }
    },
    value: {
      type: String,
      default: null,
      validator() {
        return true;
      }
    },
    hover: {
      type: Boolean,
      default: false,
      validator() {
        return true;
      }
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    valueText: function() {
      const selectedItem = this.items.find(p => p.id == this.value);
      return selectedItem != null ? selectedItem.name : "";
    },
    editMode: function() {
      return this.hover || this.focused;
    }
  },
  methods: {
    updateValue: function(item) {
      this.$emit("input", item);
      this.focused = false;
    },
    onFocus() {}
  }
};
</script>

<style lang="scss"></style>
