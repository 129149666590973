export const toTreeData = authRule => {
  let rootGroupNode = createGroupNode(authRule, true);
  return [rootGroupNode];
};

export const createGroupNode = (group, isRoot) => {
  let groupNode = {
    type: "group",
    data: {
      groupOp: group.groupOp
    },
    isRoot: isRoot,
    children: []
  };

  if (group.rules) {
    let rulesNode = createRulesNode(group.rules);
    groupNode.children.push(rulesNode);
    rulesNode.parent = groupNode;
  }

  if (group.groups) {
    let subGroupNode = createGroupNode(group.groups, false);
    groupNode.children.push(subGroupNode);
    subGroupNode.parent = groupNode;
  }

  return groupNode;
};

export const createRulesNode = rules => {
  let rulesNode = {
    type: "rules",
    data: {},
    children: []
  };

  for (let rule of rules) {
    let ruleNode = createRuleNode(rule);
    rulesNode.children.push(ruleNode);
    ruleNode.parent = rulesNode;
  }

  return rulesNode;
};

export const createRuleNode = rule => {
  let ruleNode = {
    type: "rule",
    data: {
      field: rule[0],
      operator: rule[1],
      dataset: rule[2]
    }
  };
  return ruleNode;
};

export const fromTreeData = treeData => {
  let rootNode = treeData[0];
  let authRule = createGroup(rootNode);
  return authRule;
};

const createGroup = groupNode => {
  let group = {
    groupOp: groupNode.data.groupOp
  };

  if (groupNode.children) {
    for (let childNode of groupNode.children) {
      switch (childNode.type) {
        case "group":
          group.groups = createGroup(childNode);
          break;
        case "rules":
          group.rules = createRules(childNode);
          break;
      }
    }
  }

  return group;
};

const createRules = rulesNode => {
  let rules = [];

  if (rulesNode.children) {
    for (let childNode of rulesNode.children) {
      switch (childNode.type) {
        case "rule":
          rules.push(createRule(childNode));
          break;
      }
    }
  }

  return rules;
};

const createRule = ruleNode => {
  return [ruleNode.data.field, ruleNode.data.operator, ruleNode.data.dataset];
};

export const validate = authRule => {
  let errors = [];
  validateGroup(authRule, errors);
  return errors;
};

const validateGroup = (group, errors) => {
  if (group.groupOp != "AND" && group.groupOp != "OR") {
    addError("required", "group", errors);
  }

  if (group.rules) {
    validateRules(group.rules, errors);
  }

  if (group.groups) {
    validateGroup(group.groups, errors);
  }
};

const validateRules = (rules, errors) => {
  if (!Array.isArray(rules)) {
    addError("required", "rules", errors);
  }

  for (let rule of rules) {
    validateRule(rule, errors);
  }
};

const validateRule = (rule, errors) => {
  if (!rule[0]) {
    addError("required", "rule", errors);
  }
  if (!rule[1]) {
    addError("required", "rule", errors);
  }
  if (!rule[2]) {
    addError("required", "rule", errors);
  }
};

const addError = (code, type, errors) => {
  let error = {
    code: code,
    type: type,
    text: ""
  };

  error.text += type;

  switch (code) {
    case "required":
      error.text += " required";
  }

  errors.push(error);
};

// Example:
// curl -X POST "http://localhost:30100/policies"
// -H "accept: application/json"
// -H "Content-Type: application/json"
// -d "{
// 	"resource": "/identities/{id}",
// 	"method": "GET",
// 	"ruleset": "{
// 		"groupOp":"or",
// 		"rules":[["sub|id","eq","obj|id"],["sub|tags","include","data|tag:admin"]],
// 		"groups":{
// 			"groupOp":"and",
// 			"rules":[["data|1","le","data|1"],["data|1","lt","data|1"]]
// 		}
// 	}"
// }"
