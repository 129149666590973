<template>
  <v-container fluid>
    <v-row align="center">
      <v-btn
        class="ma-2"
        color="primary"
        dark
        small
        text
        v-on:click="addClick(value)"
      >
        {{ $t("rules") }}
        <v-icon right x-small>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { createRuleNode } from "@/managers/rules";

export default {
  name: "GsRulesEditor",
  components: {},
  props: ["value"],
  data() {
    return {};
  },
  methods: {
    addClick: function(item) {
      let newRule = [null, null, null];
      let ruleNode = createRuleNode(newRule);
      item.children.push(ruleNode);
      ruleNode.parent = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.editor {
  width: 80px;
}
</style>
