<template>
  <v-treeview
    dense
    activatable
    hoverable
    rounded
    open-on-click
    :items="items.children"
    :active="activeItems"
    :open="openedItems"
    @update:active="onActive"
    @update:open="onOpen"
    item-key="id"
    item-text="name"
  >
    <template v-slot:label="{ item }">
      <div v-if="item.type == 'endpoint'" class="endpoint-leaf">
        <v-row style="padding: 0px">
          <v-col>
            <v-chip
              class="float-left ma-2 mark-method"
              :class="item.method"
              label
              >{{ item.method }}</v-chip
            >
            {{ item.id }}
            <span class="caption d-block">{{ item.name }}</span>
          </v-col>
        </v-row>
      </div>

      <div class="ma-2" v-if="item.type == 'group'">
        <strong class="d-block">{{ item.id }}</strong>
        <span class="caption">{{ item.name }}</span>
      </div>
    </template>
  </v-treeview>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import { toTreeData } from "@/managers/endpoints";

export default {
  name: "EndPoints",
  components: {},
  props: {
    resourceId: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      items: [{ children: [] }],
      openedItems: [],
      activeItems: [],
      endpointsList: null
    };
  },
  created: async function() {
    this.endpointsList = await callAPI({
      url: this.resourceId + "/_service/resources",
      method: "GET"
    });
    this.init();
  },
  methods: {
    init: function() {
      this.openFirstLevel();
      this.items.children = toTreeData(this.endpointsList.data);
    },
    openFirstLevel() {
      if (this.items == null || !Array.isArray(this.items)) return;
      if (this.items.length != 1) return;
      const rootItem = this.items[0];
      const firstLevelIds = rootItem.children.map(p => p.id);
      this.openedItems = [];
      this.openedItems.push(rootItem.id);
      this.openedItems.push(...firstLevelIds);
    },
    onOpen: function(event) {
      this.openedItems = event;
    },
    onActive: function(event) {
      this.activeItems = event;
      if (this.activeItems.length != 1) return;
      this.onClick(this.activeItems[0]);
    },
    onClick(nodeId) {
      if (this.isNodeLeaf(this.findNodeById(nodeId))) {
        this.$emit("input", {
          id: nodeId,
          name: this.findNodeById(nodeId).name
        });
      }
    },
    findNodeById(nodeId) {
      return this.findNodeByIdReq(nodeId, this.items);
    },
    findNodeByIdReq(nodeId, parentNode) {
      if (parentNode.id == nodeId) return parentNode;
      for (const childNode of parentNode.children) {
        const node = this.findNodeByIdReq(nodeId, childNode);
        if (node != null) return node;
      }
    },
    isNodeLeaf(node) {
      if (!node.children) return true;
      if (node.children.length == 0) return true;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

.mark-method {
  color: $white !important;
  font-weight: bold;
}
.GET {
  background-color: $mGreen !important;
}
.POST {
  background-color: $mBlue !important;
}
.PUT {
  background-color: $mLightGreen !important;
}
.PATCH {
  background-color: $mOrange !important;
}
.DELETE {
  background-color: $mRed !important;
}
.endpoint-leaf {
  cursor: pointer;
}
</style>
