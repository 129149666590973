<template>
  <v-container fluid>
    <v-row align="center">
      <v-btn-toggle
        v-model="selectedIndex"
        rounded
        mandatory
        @change="onChange"
      >
        <v-btn class="option" small color="primary" dark>AND</v-btn>
        <v-btn class="option" small color="primary" dark>OR</v-btn>
      </v-btn-toggle>

      <v-btn icon :disabled="hasChildGroupNode()" v-on:click="addClick()">
        <v-icon x-small style="margin: 0px; padding: 0px">mdi-plus</v-icon>
      </v-btn>
      <v-btn icon :disabled="value.isRoot" v-on:click="removeClick()">
        <v-icon x-small style="margin: 0px; padding: 0px">mdi-minus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { createGroupNode } from "@/managers/rules";

export default {
  name: "GsGroupEditor",
  components: {},
  props: ["value"],
  data() {
    return {
      items: [
        {
          id: "AND",
          name: "AND"
        },
        {
          id: "OR",
          name: "OR"
        }
      ],
      selectedIndex: "AND"
    };
  },
  methods: {
    init: function() {
      let selectedItem = this.items.find(p => p.id == this.value.data.groupOp);
      this.selectedIndex = this.items.indexOf(selectedItem);
    },
    hasChildGroupNode: function() {
      return this.value.children.find(p => p.type == "group") != null;
    },
    addClick: function() {
      let newGroup = {
        groupOp: "AND",
        rules: []
      };
      let groupNode = createGroupNode(newGroup, false);
      this.value.children.push(groupNode);
      groupNode.parent = this.value;
    },
    removeClick: function() {
      let index = this.value.parent.children.indexOf(this.value);
      this.value.parent.children.splice(index, 1);
    },
    onChange: function(event) {
      let selectedItem = this.items[event];
      this.value.data.groupOp = selectedItem.id;
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      this.init();
    });
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

.option {
  width: 60px;
  background-color: $darkgreen !important;
}
</style>
